<template>
  <v-navigation-drawer app class="sidebar" v-model="drawer">
    <div class="box-sidebar-logo mb-5">
      <img class="logo-img" src="../../assets/cet.png" alt="" />
      <h3 class="text-logo">SISTransplantes</h3>
     <!-- <h4 class="text-unity" v-if="(this.$store.state.profile_id != 1)">{{ $store.state.authUser.unity_hospital }} - {{ $store.state.authUser.unity_cnes }}</h4> -->
      <div id="example"></div>
    </div>

    <div class="box-sidebar-logo mb-5">
      <h3 class="text-profile">{{ $store.state.authUser.profile }}</h3>
      <h4 class="text-unity" v-if="(this.$store.state.profile_id != 1)">{{ $store.state.authUser.unity_hospital }} - {{ $store.state.authUser.unity_cnes }}</h4>
      <div id="example"></div>
    </div>

    <div class="card-menu" width="300">
      <v-list>
        <h6 class="text-section-menu">Menu</h6>
        <v-list-item-group class="list-group">
            <router-link to="/dashboard" class="link" active-class="link-ativo">
              <v-icon size="22" class="icon">mdi-finance</v-icon>
              <span>Dashboard</span>
            </router-link>

            <router-link to="/perfis-de-usuarios" class="link" active-class="link-ativo"  v-if="((this.$store.state.profile_id == 1) || (this.$store.state.profile_id == 4))">
              <v-icon size="22" class="icon">mdi-account-box</v-icon>
              <span>Perfis de Usuários</span>
            </router-link>

            <router-link to="/permissoes" class="link" active-class="link-ativo"  v-if="((this.$store.state.profile_id == 1) || (this.$store.state.profile_id == 4))">
              <v-icon size="22" class="icon">mdi-cog</v-icon>
              <span>Perfis e Permissões</span>
            </router-link>

            <router-link to="/busca-ativa" class="link" active-class="link-ativo" v-if="(this.$store.state.profile_id != 5)">
              <v-icon size="22" class="icon">mdi-account-search</v-icon>
              <span>Busca Ativa</span>
            </router-link>

            <router-link to="/assinatura-digital" class="link" active-class="link-ativo"  v-if="((this.$store.state.profile_id == 1) || (this.$store.state.profile_id == 4))">
              <v-icon size="22" class="icon">mdi-pencil</v-icon>
              <span>Assinatura Digital</span>
            </router-link>

            <router-link to="/equipe-medica" class="link" active-class="link-ativo" v-if="(this.$store.state.profile_id != 5)">
              <v-icon size="22" class="icon">mdi-doctor</v-icon>
              <span>Equipe Médica</span>
            </router-link>
        </v-list-item-group>

        <v-list-group class="list-group">
          <template v-slot:activator class="menuzinho">
            <v-icon size="25" color="#086318" class="mr-1">mdi-account-child-circle</v-icon>
              <span>Doador</span>
          </template>

          <router-link to="/doador" class="link ml-2 mr-2" active-class="link-ativo">
            <span>Visualizar</span>
          </router-link>

          <router-link to="/cadastrar-doador" class="link ml-2 mr-2" active-class="link-ativo" v-if="((this.$store.state.profile_id != 5))">
            <span>Cadastrar</span>
          </router-link>

          <!-- <router-link to="/exames-de-entrada" class="link ml-2 mr-2" active-class="link-ativo" v-if="((this.$store.state.profile_id != 5))">
            <span>Exames de Entrada</span>
          </router-link> -->

          <router-link to="/sorologia" class="link ml-2 mr-2" active-class="link-ativo" v-if="(this.$store.state.profile_id == 5)">
            <span>Sorologia</span>
          </router-link>

          <router-link to="/captacao" class="link ml-2 mr-2" active-class="link-ativo" v-if="((this.$store.state.profile_id != 5))">
            <span>Captação</span>
          </router-link>
        </v-list-group>

        <v-list-group class="list-group"  v-if="((this.$store.state.profile_id != 5))">
          <template v-slot:activator class="menuzinho">
            <v-icon size="25" color="#086318" class="mr-1">mdi-account-child</v-icon>
              <span>Receptor</span>
          </template>

          <router-link to="/receptor" class="link ml-2 mr-2" active-class="link-ativo">
            <span>Visualizar</span>
          </router-link>

          <router-link to="/cadastrar-receptor" class="link ml-2 mr-2" active-class="link-ativo">
            <span>Cadastrar</span>
          </router-link>
        </v-list-group>

        <!-- <v-list-group class="list-group" v-if="((this.$store.state.profile_id != 5))">
          <template v-slot:activator class="menuzinho">
            <v-icon size="25" color="#086318" class="mr-1">mdi-heart-pulse</v-icon>
              <span>Orgãos</span>
          </template>
        </v-list-group> -->

      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
//import axios from "@/axios";

export default {
  data() {
    return {
      drawer: null,
    };
  },

  props: {
    resource: {
      type: Boolean
    },
  },

  watch: {
    resource: function () {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style>
.v-navigation-drawer__border {
  width: 0px !important;
}

.sidebar {
  background: transparent !important;
  padding: 20px;
}

.box-sidebar-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  background: #e55d87; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    160deg,
    rgb(146, 51, 155) 0%,
    rgba(93, 148, 229, 1) 100%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    160deg,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 100%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;
  padding: 10px;
}

.logo-img {
  height: 205px;
  width: 158px;
}

.text-logo {
  font-size: 15px;
  font-weight: 500;
  color: #086318;
  margin: 0;
}

.text-profile {
  font-size: 15px;
  font-weight: 500;
  color: #086318;
  margin: 0;
}
.text-unity {
  font-size: 12px;
  font-weight: 500;
  color: #086318;
  margin: 0;
}

.text-system-name {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #115726;
}

.card-menu {
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
}

.text-section-menu {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 5px;
}

.list-group {
  display: flex;
  flex-direction: column;
}

/* .list-group:hover {
  background: #dff7e4;
  border-radius: 10px;
  transition: 0.8s;
} */

span {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #000000;
}

.link {
  padding: 10px;
  margin: 5px ;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.8s;
}

.link:hover {
  background: #dff7e4;
  border-radius: 10px;
  transition: 0.8s;
}

.link > .icon {
  margin-right: 5px;
  color: #086318 !important;
}

icon {
  margin-right: 10px;
  color: #e62c2c !important;
}

.link > span {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #000000;
}

.link-ativo {
  background: #daf5e0;
  border: 1px solid #84b87f;
  border-radius: 10px;
  color: red;
}

/* Mobile */
.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
  background: rgb(248, 248, 248) !important;
}
</style>
