<template>
  <div>
    <Sidebar :resource="drawer" />

    <v-app-bar color="#FFFFFF" app class="navbar" elevation="0">
      <div class="app-itens">
        <div class="sidebar-action">
          <v-btn
            @click="clicando"
            color="#09b86c"
            icon
            elevation="0"
            small
          >
            <v-icon color="#09b86c">{{ menuIcon }}</v-icon>
          </v-btn>

          <span class="pageIndicator">{{
            this.$router.history.current.name
          }}</span>
        </div>

        <Menu :resource="authUser" />
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import Sidebar from "../sidebar/Sidebar-comp.vue";
import Menu from "./MenuNavbar-comp.vue";
import axios from "@/axios";

export default {
  data() {
    return {
      drawer: null,
      menuIcon: "mdi-menu",
      authUser: {
        initials: "",
        fullName: "",
        email: "",
      },
    };
  },

  components: {
    Sidebar,
    Menu,
  },

  created() {
    axios.get(`/auth/me`).then((response) => {
      this.authUser.initials = response.data.name.substr(0, 1);
      this.authUser.fullName = response.data.name.split(' ')[0];
      this.authUser.email = response.data.email;
    });
  },

  methods: {
    clicando() {
      this.drawer = !this.drawer
    }
  }
};
</script>

<style>
.navbar {
  position: absolute !important;
  margin-top: 20px !important;
  margin: 20px;
  border-radius: 10px !important;
}

.app-itens {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sidebar-action {
  display: flex;
  align-items: center;
}

.sidebar-action > .pageIndicator {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  margin-left: 10px;
}
</style>
